import Header from "./components/header";
import Footer from "./components/footer";
import Card from "./components/card";
import lazyLoader from "./components/lazyLoader";
import { getSuggestions } from "./services/smart404";
import LazyLoader from "./components/lazyLoader";
import SearchWidget from "./components/searchWidget";
import FavouritesButton from "./components/favouritesButton";

new Header(document.querySelector('header'), true);

new LazyLoader('.lazy-load');

new SearchWidget(document.getElementById('search-widget'));

new Footer(document.querySelector('footer'));

var heading = document.querySelector('h1');
var message = document.querySelector('p.message');

var button = document.querySelector('button');
button.addEventListener('click', createSuggestions);

async function createSuggestions() {
    // Fetch suggestions
    const suggestions = await getSuggestions(location.pathname);

    // Counts
    const firstCount = suggestions.first.cards.length;
    const secondCount = suggestions.second.cards.length;
    const thirdCount = suggestions.third.cards.length;
    const totalCount = firstCount + secondCount + thirdCount;

    if (totalCount === 0) {
        heading.style.display = 'none';
        message.innerHTML = com.tcl.data.i18n.i18n_NO_SUGGESTIONS_FOUND;
        button.style.display = 'none';

        return;
    }

    // Add cards
    var main = document.getElementById('main');
    var template = null;

    if (suggestions.first.cards.length > 0) {
        template = document.querySelector('template.' + suggestions.first.type).content.cloneNode(true);
        template.querySelector('.cards').innerHTML = suggestions.first.cards.join('');

        main.appendChild(template);
    }

    if (suggestions.second.cards.length > 0) {
        template = document.querySelector('template.' + suggestions.second.type).content.cloneNode(true);
        template.querySelector('.cards').innerHTML = suggestions.second.cards.join('');

        main.appendChild(template);
    }

    if (suggestions.third.cards.length > 0) {
        template = document.querySelector('template.' + suggestions.third.type).content.cloneNode(true);
        template.querySelector('.cards').innerHTML = suggestions.third.cards.join('');

        main.appendChild(template);
    }

    for (let card of document.querySelectorAll('.product-card')) {
        Card.initialiseCard(card);
    }

    new lazyLoader('.lazy-load');

    heading.style.display = 'none';
    message.style.display = 'none';
    button.style.display = 'none';
}

document.addEventListener('click', (e) => {
    if (e.target.classList.contains('card-favourite')) {
        e.preventDefault();

        const saved = e.target.classList.contains('saved');
        const element = e.target; const cardElement = e.target.closest('.product-card');
        const id = cardElement.dataset.id;
        const title = cardElement.dataset.title;

        if (saved) {
            let favourites = localStorage.getItem(`favourites`)?.split('|').filter(Boolean);
            if (favourites === null || favourites === undefined)
                return;
            favourites.splice(favourites.indexOf(id), 1);
            localStorage.setItem(`favourites`, favourites.join('|'));
        }
        else {
            let favourites = localStorage.getItem(`favourites`)?.split('|').filter(Boolean)
            if (favourites === null || favourites === undefined)
                favourites = [];
            favourites.push(id);
            localStorage.setItem(`favourites`, favourites.join('|'));
        }

        FavouritesButton.updateFavouritesCount();
        FavouritesButton.showNotification(title, !saved);

        if (!saved) {
            element.classList.add('saved');
        }
        else {
            if (element.parentElement.classList.contains('favourite-card')) {
                element.parentElement.remove();
            }

            element.classList.remove('saved');
        }
    }
});
